<template>
  <v-footer padless class="grey lighten-2 mt-2">
    <div
      class="footer-info-row primary d-flex flex-wrap pa-5 pa-sm-7 align-start justify-center w-100"
    >
      <img
        class="mini-logo mr-3 mt-2 mb-3 mb-lg-0"
        src="/img_layout/footer/footer-logo.png"
        alt="Logo Basko"
      />
      <i18n
        path="footer.infoRow.text"
        tag="span"
        class="info-row-text text-h6 mr-2 font-weight-semibold"
      >
        <template v-slot:mail>
          <a
            :href="'mailto:' + $t('footer.infoRow.mail')"
            class="mail-link white--text text-decoration-none"
          >
            <span class="font-weight-bold">
              {{ $t("footer.infoRow.mail") }}</span
            >
          </a>
        </template>
        <template v-slot:wNumber>
          <a
            target="_blank"
            :href="$t('footer.infoRow.wNumber')"
            class="mail-link white--text text-h2 text-decoration-none"
          >
            <span class="font-weight-bold">
              {{ $t("footer.infoRow.number") }}</span
            >
          </a>
        </template>
        <template v-slot:phone>
          <a
            :href="'tel:' + $t('footer.infoRow.phone')"
            class="phone-number-link white--text text-h2 text-decoration-none"
          >
            {{ $t("footer.infoRow.phone") }}
          </a>
        </template>
      </i18n>
    </div>

    <v-container fluid class="footer-container pt-6">
      <v-row class="py-5">
        <v-col cols="12">
          <v-row v-if="links && $vuetify.breakpoint.smAndUp">
            <!-- custom layout for column with "socials" CSS class, showing button for app download -->
            <!-- "socials" column not rendered on mobile app -->
            <v-col
              v-for="(link, index) in links"
              :key="index"
              cols="12"
              sm="6"
              md="3"
              class="flex-column justify-start align-center align-md-start"
              :class="isCordovaPlatform(link.classes) ? 'd-none' : 'd-flex'"
            >
              <h4
                class="text-body-1 mb-3"
                :class="
                  isSocialLinksColumn(link.classes)
                    ? 'mt-4 mt-sm-0 text-body-2 font-weight-bold text-center text-sm-left'
                    : 'text-center font-weight-bold text-sm-left mt-4'
                "
              >
                {{ link.title }}
              </h4>
              <div class="d-flex flex-column align-center align-md-start">
                <a
                  v-for="child in link.children"
                  :key="child.ID"
                  :href="child.url"
                  class="text-decoration-none default--text my-1"
                  :class="[child.classes, { 'no-evt': index == 2 }]"
                  :target="child.target || '_self'"
                  @click="clickLink"
                >
                  <span :class="child.classes">{{ child.title }}</span>
                </a>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="d-flex flex-column justify-start align-center align-md-start"
            >
              <div class="social-block">
                <h4
                  class="text-body-1 mb-3 text-center font-weight-bold text-sm-left mt-4"
                >
                  {{ $t("footer.socialColumn.title") }}
                </h4>
                <div class="d-flex flex-column align-center align-md-start">
                  <span class="d-block default--text my-1">{{
                    $t("footer.socialColumn.proposal")
                  }}</span>
                  <div class="social-url-container d-flex align-center mt-2">
                    <a
                      class="social-url"
                      :href="$t('footer.socialColumn.fbUrl')"
                      target="_blank"
                    >
                      <img
                        src="/img_layout/footer/facebook.svg"
                        alt="facebook"
                        title="facebook"
                      />
                    </a>
                    <a
                      class="social-url"
                      :href="$t('footer.socialColumn.ytUrl')"
                      target="_blank"
                    >
                      <img
                        src="/img_layout/footer/youtube.svg"
                        alt="youtube"
                        title="youtube"
                      />
                    </a>
                    <a
                      class="social-url"
                      :href="$t('footer.socialColumn.igUrl')"
                      target="_blank"
                    >
                      <img
                        src="/img_layout/footer/instagram.svg"
                        alt="instagram"
                        title="instagram"
                      />
                    </a>
                  </div>
                  <span class="d-block default--text mt-5 mb-1">{{
                    $t("footer.socialColumn.downloadApp")
                  }}</span>
                  <div class="app-container d-flex flex-column">
                    <a
                      class="social-url app-url d-flex align-center mb-3"
                      :href="$t('footer.socialColumn.googlePlayUrl')"
                      target="_blank"
                    >
                      <img
                        class="mr-2"
                        src="/img_layout/footer/play-store.svg"
                        alt="play store"
                        title="play store"
                      />
                      {{ $t("footer.socialColumn.googleApp") }}</a
                    >
                    <a
                      class="social-url app-url d-flex align-center"
                      :href="$t('footer.socialColumn.appleStoreUrl')"
                      target="_blank"
                    >
                      <img
                        class="mr-2"
                        src="/img_layout/footer/apple-store.svg"
                        alt="apple store"
                        title="apple store"
                      />
                      {{ $t("footer.socialColumn.iosApp") }}</a
                    >
                  </div>
                </div>
                <!-- <a
              href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.basko"
              target="_blank"
            >
              <img
                class="d-block my-2"
                src="/img_layout/footer/google-play.png"
                alt="Immagine google play"
              />
            </a> -->
                <!-- <a
              href="https://geo.itunes.apple.com/it/app/basko/id1055600644?mt=8"
              target="_blank"
            >
              <img
                class="d-block"
                src="/img_layout/footer/app-store.png"
                alt="Immagine app store"
              />
            </a> -->
              </div>
            </v-col>
          </v-row>
          <div v-else-if="links && $vuetify.breakpoint.xsOnly">
            <v-expansion-panels
              accordion
              flat
              tile
              :focusable="selectedTab"
              class="accordion-footer"
            >
              <v-expansion-panel
                v-for="(link, index) in links"
                :key="index"
                class="grey lighten-2"
              >
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon>$expand</v-icon>
                  </template>
                  <div class="font-weight-bold" v-html="link.title" />
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-2">
                  <div class="d-flex flex-column">
                    <a
                      v-for="child in link.children"
                      :key="child.ID"
                      :href="child.url"
                      class="text-decoration-none default--text my-1"
                      :class="[child.classes, { 'no-evt': index == 2 }]"
                      :target="child.target"
                      @click="clickLink"
                    >
                      <span :class="child.classes">{{ child.title }}</span>
                    </a>
                  </div>
                </v-expansion-panel-content>
                <v-divider />
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="social-block">
              <h4
                class="text-body-1 mb-3 text-center font-weight-bold text-sm-left mt-4"
              >
                {{ $t("footer.socialColumn.title") }}
              </h4>
              <div class="d-flex flex-column align-center align-md-start">
                <span class="d-block default--text my-1">{{
                  $t("footer.socialColumn.proposal")
                }}</span>
                <div class="social-url-container d-flex align-center mt-2">
                  <a
                    class="social-url"
                    :href="$t('footer.socialColumn.fbUrl')"
                    target="_blank"
                  >
                    <img
                      src="/img_layout/footer/facebook.svg"
                      alt="facebook"
                      title="facebook"
                    />
                  </a>
                  <a
                    class="social-url"
                    :href="$t('footer.socialColumn.ytUrl')"
                    target="_blank"
                  >
                    <img
                      src="/img_layout/footer/youtube.svg"
                      alt="youtube"
                      title="youtube"
                    />
                  </a>
                  <a
                    class="social-url"
                    :href="$t('footer.socialColumn.igUrl')"
                    target="_blank"
                  >
                    <img
                      src="/img_layout/footer/instagram.svg"
                      alt="instagram"
                      title="instagram"
                    />
                  </a>
                </div>
                <span class="d-block default--text mt-5 mb-1">{{
                  $t("footer.socialColumn.downloadApp")
                }}</span>
                <div class="app-container d-flex flex-column">
                  <a
                    class="social-url app-url d-flex align-center mb-3"
                    :href="$t('footer.socialColumn.googlePlayUrl')"
                    target="_blank"
                  >
                    <img
                      class="mr-2"
                      src="/img_layout/footer/play-store.svg"
                      alt="play store"
                      title="play store"
                    />
                    {{ $t("footer.socialColumn.googleApp") }}</a
                  >
                  <a
                    class="social-url app-url d-flex align-center"
                    :href="$t('footer.socialColumn.appleStoreUrl')"
                    target="_blank"
                  >
                    <img
                      class="mr-2"
                      src="/img_layout/footer/apple-store.svg"
                      alt="apple store"
                      title="apple store"
                    />
                    {{ $t("footer.socialColumn.iosApp") }}</a
                  >
                </div>
              </div>
              <!-- <a
              href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.basko"
              target="_blank"
            >
              <img
                class="d-block my-2"
                src="/img_layout/footer/google-play.png"
                alt="Immagine google play"
              />
            </a> -->
              <!-- <a
              href="https://geo.itunes.apple.com/it/app/basko/id1055600644?mt=8"
              target="_blank"
            >
              <img
                class="d-block"
                src="/img_layout/footer/app-store.png"
                alt="Immagine app store"
              />
            </a> -->
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <div
        class="mt-4 mb-12 d-flex flex-column flex-sm-row justify-center align-start"
      >
        <img
          src="/logo/logo-site.svg"
          alt="Logo"
          width="172px"
          class="mr-4 mt-1 mb-3"
        />
        <span
          class="copyright"
          v-html="$t('footer.copyright', { ver: version })"
        >
        </span>
      </div>
    </v-container>
  </v-footer>
</template>
<style lang="scss">
.v-footer {
  .phone-number-link {
    white-space: nowrap;
  }
  .footer-container {
    a {
      text-decoration: none;
      font-size: $font-size-root;
      color: var(--v-default-base);
    }
    .social-url-container {
      gap: 25px;
    }
  }
  .mini-logo {
    max-width: 100px;
  }
}
</style>
<script>
import clickHandler from "@/commons/mixins/clickHandler";

export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true }
  },
  data() {
    return {
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    clickLink: function(event) {
      if (event.target.className.includes("cookiebot-widget")) {
        if (window.Cookiebot) {
          window.Cookiebot.show();
        }
      } else {
        this.clicked(event);
      }
    }
  }
};
</script>
